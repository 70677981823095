import { Link } from 'gatsby';
import React from 'react';

export default (props) => (
  <footer style={{ marginTop: 32, padding: 16, zIndex: 10, backgroundColor: 'whitesmoke' }}>
    <div className={'col-xs-12'}>
      <div className="row center-xs">
        <Link to={'/vita'} className={'is-link'} style={{ fontSize: 12 }}>Vita</Link>
        &nbsp;|&nbsp;
        <Link to={'/contact'} className={'is-link'} style={{ fontSize: 12 }}>Kontakt</Link>
      </div>
      <div className="row center-xs">
        <p style={{ fontSize: 12 }}>
          {props.title} © <time>{new Date().getFullYear()}</time><br/>
          <Link to={'/impressum'} className={'is-link'} style={{ fontSize: 12 }}>Impressum</Link>
        </p>
      </div>
    </div>
  </footer>
);
