import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const HeaderPage = ({ data: { site: { siteMetadata: { title: pageTitle } }, services: { edges }, examples: { edges: exampleList } } }) => {
  const [nav, setNav] = React.useState(false);
  const [more, showMore] = React.useState(false);
  const [more2, showMore2] = React.useState(false);
  const publishedExamples = Boolean(exampleList.length);
  return (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link
          to="/"
          className="navbar-item title is-size-2-desktop is-size-3-tablet is-size-4-mobile mr-3 is-marginless is-syncopate">
          {pageTitle}
        </Link>
        <a role="button" className={`navbar-burger burger is-88-high ${nav ? 'is-active' : ''}`} aria-label="menu"
          aria-expanded="false"
          onClick={() => setNav(!nav)}
          data-target="navbarBasicExample">
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${nav ? 'is-active' : ''}`}>
        <div className="navbar-start">
          <a className="navbar-item" href={'/atelier'}>
            Atelier
          </a>
          <hr className="divider is-hidden-desktop is-hidden-tablet"/>

          <a className="navbar-item" href={'/vita'}>
            Vita
          </a>
          <hr className="divider is-hidden-desktop is-hidden-tablet"/>

          <div className={`navbar-item has-dropdown is-hoverable ${more ? 'is-active' : ''}`}
            onClick={() => showMore(!more)}
          >
            <a className="navbar-link" key={'leistungen'}>
              Leistungen
            </a>

            <div className="navbar-dropdown">
              {edges.map(({ node: { frontmatter: { title }, id, fields: { slug } } }, i) => (
                <React.Fragment key={id}>
                  <Link to={slug} className="navbar-item is-size-5">
                    {title}
                  </Link>
                  {(i !== edges.length - 1) && <hr className="divider"/>}
                </React.Fragment >
              ))}
            </div>
          </div>
          <hr className="divider is-hidden-desktop is-hidden-tablet"/>

          <div className={`navbar-item has-dropdown is-hoverable ${more2 ? 'is-active' : ''}`}
            onClick={() => showMore2(!more2)}
          >
            <a className="navbar-link">
              Referenzen
            </a>
            <hr className="divider"/>

            <div className="navbar-dropdown">
              <Link to={'/examples'} className="navbar-item is-size-5">
                Projektbeispiele
              </Link>
              <hr className="divider"/>

              <Link to={'/clients'} className="navbar-item is-size-5">
                Auftraggeber
              </Link>
            </div>
          </div>

        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-light" href={'/contact'}>
                <span className="icon">
                  <i className="fas fa-envelope"/>
                </span>
                <strong>kontakt</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

  );
};

HeaderPage.propTypes = {
  siteTitle: PropTypes.string
};

export default (props) => <StaticQuery
  query={graphql`
        query {
          services: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "service-page" } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        templateKey
                        description
                    }
                     fields {
                        slug
                    }
                }
            }
          }
          site {
            siteMetadata {
                title
                }
            }
          examples: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "example"}}}) {
            edges {
              node {
                id
              }
            }
          }
        }
      `}
  render={data => (<HeaderPage key={'headerr'} data={data} {...props} />)}
/>;
