/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import dsgvo from './dsgvo';
import HeaderPage from './header';
import './all.scss';

import Claimer from './claimer';
import Footer from './Footer';

const Layout = ({ children, title }) => {
  const [open, openDsgvo] = useState(false);

  return (
    <>

      <HeaderPage siteTitle={title}/>

      <div
        className={'main-wrapper'}>
        <Claimer open={openDsgvo}/>
        <main>{children}</main>
        <Footer title={'Schittler Restaurierung'}/>
        {open &&
        <div className={'modal is-active is-overlay'} style={{ zIndex: 100 }}>
          <div className="modal-background"/>
          <div className="modal-content" style={{ backgroundColor: 'white' }}>
            {dsgvo()}
          </div>
          <button className="modal-close is-large" onClick={() => openDsgvo(false)} aria-label="close"/>
        </div>
        }
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

export const pageQuery = graphql`
    query Layout {
        site {
            siteMetadata {
                title
            }
        }
    } 
`;
