import React, { useState } from 'react';

export default function Claimer (props) {
  const isBrowser = typeof window !== 'undefined';

  const [read, setRead] = useState(() => {
    if (isBrowser) {
      const store = localStorage.getItem('confirmation');
      return !!store;
    } else return false;
  });

  function setOk (value) {
    setRead(value);
    if (isBrowser) {
      localStorage.setItem('confirmation', 'true');
    }
  }

  const offset = isBrowser ? (window.innerHeight - 190) : 0;

  return (
    !read && <div className={`${read ? 'is-hidden' : 'is-overlay'}`}
      style={{
        backgroundColor: 'rgba(0,0,0,.4)',
        zIndex: 99,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}>
      <div style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '2% 10%',
        backgroundColor: 'white'
      }}>
        <h4 className={'has-text-weight-bold'}>Datenschutz</h4>
        <br/>
        <p>
          Durch die Nutzung dieser Webseite erklären Sie sich mit der Verwendung von Cookies einverstanden.
          Es werden keine der so gesammelten Daten genutzt, um Sie zu identifizieren oder zu kontaktieren.
        </p>
        <div className="button" style={{ marginRight: 16, marginTop: 8 }}
          onClick={() => props.open(true)}>
          <a style={{ fontWeight: 600, letterSpacing: '.1rem', fontSize: 11 }}
            className="is-uppercase"
          >
            Datenschutzerklärung
          </a>
        </div>
        <div className="button" style={{ marginTop: 8 }}
          onClick={() => setOk(true)}>
          <a style={{ fontWeight: 600, letterSpacing: '.1rem', fontSize: 11 }}
            className="is-uppercase"
          >
            Weiter
          </a>
        </div>
      </div>
    </div>
  );
}
