import React from 'react';

export default () => (
  <div className="column">
    <h1 className="adsimple-311173621">Datenschutzerklärung</h1>
    <h2 className="adsimple-311173621">Datenschutz</h2>
    <p>Wir haben diese Datenschutzerklärung (Fassung 03.05.2020-311173621) verfasst, um Ihnen gemäß der Vorgaben der <a
      className="adsimple-311173621"
      href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311173621" target="_blank"
      rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> zu erklären, welche Informationen wir sammeln, wie
      wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
    <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der
      Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</p>
    <h2 className="adsimple-311173621">Automatische Datenspeicherung</h2>
    <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
      auch auf dieser Webseite.</p>
    <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite
      gespeichert ist) automatisch Daten wie</p>
    <ul className="adsimple-311173621">
      <li className="adsimple-311173621">die Adresse (URL) der aufgerufenen Webseite</li>
      <li className="adsimple-311173621">Browser und Browserversion</li>
      <li className="adsimple-311173621">das verwendete Betriebssystem</li>
      <li className="adsimple-311173621">die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
      <li className="adsimple-311173621">den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
      </li>
      <li className="adsimple-311173621">Datum und Uhrzeit</li>
    </ul>
    <p>in Dateien (Webserver-Logfiles).</p>
    <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese
      Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten
      eingesehen werden.</p>
    <h2 className="adsimple-311173621">Speicherung persönlicher Daten</h2>
    <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
      E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
      Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen
      Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
    <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich
      wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre
      persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
      von rechtswidrigem Verhalten eingesehen werden.</p>
    <p>Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit abseits dieser Webseite &#8211; können wir keine
      sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals
      unverschlüsselt per E-Mail zu übermitteln.</p>
    <p>Die Rechtsgrundlage besteht nach <a className="adsimple-311173621"
      href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311173621"
      target="_blank" rel="noopener">Artikel 6 Absatz 1 a DSGVO</a> (Rechtmäßigkeit
      der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben.
      Sie können diesen Einwilligung jederzeit widerrufen &#8211; eine formlose E-Mail reicht aus, Sie finden unsere
      Kontaktdaten im Impressum.</p>
    <h2 className="adsimple-311173621">Rechte laut Datenschutzgrundverordnung</h2>
    <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
    <ul className="adsimple-311173621">
      <li className="adsimple-311173621">Recht auf Berichtigung (Artikel 16 DSGVO)</li>
      <li className="adsimple-311173621">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
      <li className="adsimple-311173621">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
      <li className="adsimple-311173621">Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang mit der
        Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
      </li>
      <li className="adsimple-311173621">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
      <li className="adsimple-311173621">Widerspruchsrecht (Artikel 21 DSGVO)</li>
      <li className="adsimple-311173621">Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung —
        einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
      </li>
    </ul>
    <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
      datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die <a
      className="adsimple-311173621" className="311173621" href="https://www.bfdi.bund.de" target="_blank"
      rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
    <h2 className="adsimple-311173621">TLS-Verschlüsselung mit https</h2>
    <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung <a
      className="adsimple-311173621"
      href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311173621"
      target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer
      Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz
      vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen
      Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer
      Internetadresse.</p>
    <h2 className="adsimple-311173621">Google Fonts Datenschutzerklärung</h2>
    <p>Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma Google Inc.
      Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4,
      Irland) für alle Google-Dienste verantwortlich.</p>
    <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters
      werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die
      Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und
      Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie
      keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt
      werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert
      diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
    <h3 className="adsimple-311173621">Was sind Google Fonts?</h3>
    <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a
      className="adsimple-311173621" href="https://de.wikipedia.org/wiki/Google_LLC?tid=311173621">Google</a> seinen
      Nutzern kostenlos zu Verfügung stellt.</p>
    <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der
      Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
    <h3 className="adsimple-311173621">Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
    <p>Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf unseren
      eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
      halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell
      für die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige
      Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche
      Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu
      Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen
      Content Delivery Networks (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts
      unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert
      zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone,
      iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich
      wie möglich darstellen können.</p>
    <h3 className="adsimple-311173621">Welche Daten werden von Google gespeichert?</h3>
    <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
      externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
      IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
      Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig
      ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im
      Softwarebereich.</p>
    <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten
      Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht
      Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des
      eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der
      BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice
      BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
    <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen,
      IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die
      Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von
      Google nicht eindeutig kommuniziert.</p>
    <h3 className="adsimple-311173621">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU
      angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet
      ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern
      kann.</p>
    <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
      Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden
      sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten
      wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu
      erhöhen und das Design zu verbessern.</p>
    <h3 className="adsimple-311173621">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
      werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie
      den Google-Support auf <a className="adsimple-311173621"
      href="https://support.google.com/?hl=de&amp;tid=311173621">https://support.google.com/?hl=de&amp;tid=311173621</a> kontaktieren.
      Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
    <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
      also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu
      Google Fonts und weiteren Fragen finden Sie auf <a className="adsimple-311173621"
      href="https://developers.google.com/fonts/faq?tid=311173621">https://developers.google.com/fonts/faq?tid=311173621</a>.
      Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über
      Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über
      gespeicherten Daten zu bekommen.</p>
    <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch
      auf <a className="adsimple-311173621"
      href="https://policies.google.com/privacy?hl=de&amp;tid=311173621">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.
    </p>
    <h2 className="adsimple-311173621">YouTube Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf
      unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist. Betrieben
      wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Website
      eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser automatisch mit den Servern
      von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten übertragen. Für die gesamte
      Datenverarbeitung im europäischen Raum ist Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
      verantwortlich.</p>
    <p>Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos
      eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.</p>
    <h3 className="adsimple-311173621">Was ist YouTube?</h3>
    <p>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die
      letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf unserer
      Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
      haben.</p>
    <h3 className="adsimple-311173621">Warum verwenden wir YouTube-Videos auf unserer Webseite?</h3>
    <p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die
      bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei nicht
      fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern weiteren
      hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten
      Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google – dank der gesammelten
      Daten – diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote interessieren.</p>
    <h3 className="adsimple-311173621">Welche Daten werden von YouTube gespeichert?</h3>
    <p>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein Cookie,
      das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube Ihre
      Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
      Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie Browsertyp, Bildschirmauflösung
      oder Ihr Internetanbieter. Weitere Daten können Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über
      Social Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.</p>
    <p>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit einer
      eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise Ihre
      bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden, da weniger
      Cookies gesetzt werden.</p>
    <p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits
      Cookies, die ohne angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit angemeldetem
      Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von den
      Interaktionen auf YouTube abhängen.</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> YSC<br/>
      <strong className="adsimple-311173621">Wert:</strong> b9-CV6ojI5Y311173621-1<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um
      Statistiken des gesehenen Videos zu speichern.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach Sitzungsende</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> PREF<br/>
      <strong className="adsimple-311173621">Wert:</strong> f1=50000000<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre
      eindeutige ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 8 Monaten</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> GPS<br/>
      <strong className="adsimple-311173621">Wert:</strong> 1<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf
      mobilen Geräten, um den GPS-Standort zu tracken.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 30 Minuten</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> VISITOR_INFO1_LIVE<br/>
      <strong className="adsimple-311173621">Wert:</strong> 95Chz8bagyU<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users
      auf unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 8 Monaten</p>
    <p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> APISID<br/>
      <strong className="adsimple-311173621">Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7311173621-<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über
      Ihre Interessen zu erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> CONSENT<br/>
      <strong className="adsimple-311173621">Wert:</strong> YES+AT.de+20150628-20-0<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung
      eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu
      überprüfen und Userdaten vor unbefugten Angriffen zu schützen.<br/>
      <strong className="adsimple-311173621">Ablaufdatum: </strong>nach 19 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> HSID<br/>
      <strong className="adsimple-311173621">Wert:</strong> AcRwpgUik9Dveht0I<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über
      Ihre Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu können.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> LOGIN_INFO<br/>
      <strong className="adsimple-311173621">Wert:</strong> AFmmF2swRQIhALl6aL…<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> In diesem Cookie werden Informationen über Ihre
      Login-Daten gespeichert.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> SAPISID<br/>
      <strong className="adsimple-311173621">Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es Ihren
      Browser und Ihr Gerät eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre Interessen zu erstellen.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> SID<br/>
      <strong className="adsimple-311173621">Wert:</strong> oQfNKjAsI311173621-<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Google-Konto-ID und
      Ihren letzten Anmeldezeitpunkt in digital signierter und verschlüsselter Form.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> SIDCC<br/>
      <strong className="adsimple-311173621">Wert:</strong> AN0-TYuqub2JOcDTyL<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie Sie
      die Webseite nutzen und welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise gesehen haben.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 3 Monaten</p>
    <h3 className="adsimple-311173621">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die meisten
      dieser Server befinden sich in Amerika. Unter <a className="adsimple-311173621"
      href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
      target="_blank"
      rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a> sehen
      Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind die Daten
      schneller abrufbar und vor Manipulation besser geschützt.</p>
    <p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere
      werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit
      gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem
      Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in einem
      Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
      löschen.</p>
    <h3 className="adsimple-311173621">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatische
      Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer
      Entscheidung &#8211; entweder 3 oder 18 Monate gespeichert und dann gelöscht.</p>
    <p>Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies von
      Google gelöscht bzw. deaktiviert werden. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf
      unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
    <p>
      <a className="adsimple-311173621" href="https://support.google.com/chrome/answer/95647?tid=311173621"
        target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a className="adsimple-311173621" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311173621"
        target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a className="adsimple-311173621"
        href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311173621"
        target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
        Ihrem Computer abgelegt haben</a>
    </p>
    <p>
      <a className="adsimple-311173621"
        href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311173621"
        target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a className="adsimple-311173621"
        href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311173621" target="_blank"
        rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
      informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
      erlauben oder nicht. Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame
      Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die
      Datenschutzerklärung unter <a className="adsimple-311173621" href="https://policies.google.com/privacy?hl=de"
      target="_blank"
      rel="noopener noreferrer">https://policies.google.com/privacy?hl=de.</a>
    </p>
    <h2 className="adsimple-311173621">Vimeo Datenschutzerklärung</h2>
    <p>Wir verwenden auf unserer Website auch Videos der Firma Vimeo. Betrieben wird das Videoportal durch Vimeo LLC,
      555 West 18th Street, New York, New York 10011, USA. Mit Hilfe eines Plug-ins können wir Ihnen so interessantes
      Videomaterial direkt auf unserer Website anzeigen. Dabei können gewissen Daten von Ihnen an Vimeo übertragen
      werden. In dieser Datenschutzerklärung zeigen wir Ihnen, um welche Daten es sich handelt, warum wir Vimeo
      verwenden und wie Sie Ihre Daten bzw. die Datenübertragung verwalten oder unterbinden können.</p>
    <h3 className="adsimple-311173621">Was ist Vimeo?</h3>
    <p>Vimeo ist eine Videoplattform, die 2004 gegründet wurde und seit 2007 das Streamen von Videos in HD-Qualität
      ermöglicht. Seit 2015 kann auch in 4k Ultra HD gestreamt werden. Die Nutzung des Portals ist kostenlos, es kann
      allerdings auch kostenpflichtiger Content veröffentlicht werden. Im Vergleich zum Marktführer YouTube, legt Vimeo
      vorrangig Wert auf hochwertigen Content in guter Qualität. So bietet das Portal einerseits viele künstlerische
      Inhalte wie Musikvideos und Kurzfilme, andererseits aber auch wissenswerte Dokumentationen zu den
      unterschiedlichsten Themen.</p>
    <h3 className="adsimple-311173621">Warum verwenden wir Vimeo auf unserer Webseite?</h3>
    <p>Ziel unserer Webpräsenz ist es, Ihnen den bestmöglichen Content zu liefern. Und zwar so einfach zugänglich wie
      möglich. Erst wenn wir das geschafft haben, sind wir mit unserem Service zufrieden. Der Videodienst Vimeo
      unterstützt uns dieses Ziel zu erreichen. Vimeo bietet uns die Möglichkeit, Ihnen qualitativ hochwertige Inhalte
      direkt auf unserer Website zu präsentieren. Statt Ihnen nur einen Link zu einem interessanten Video zu geben,
      können Sie so das Video gleich bei uns ansehen. Das erweitert unser Service und erleichtert Ihnen den Zugang zu
      interessanten Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video-Content an.</p>
    <h3 className="adsimple-311173621">Welche Daten werden auf Vimeo gespeichert?</h3>
    <p>Wenn Sie auf unserer Website eine Webseite aufrufen, die ein Vimeo-Video eingebettet hat, verbindet sich Ihr
      Browser mit den Servern von Vimeo. Dabei kommt es zu einer Datenübertragung. Diese Daten werden auf den
      Vimeo-Servern gesammelt, gespeichert und verarbeitet. Unabhängig davon, ob Sie ein Vimeo-Konto haben oder nicht,
      sammelt Vimeo Daten über Sie. Dazu zählen Ihre IP-Adresse, technische Infos über Ihren Browsertyp, Ihr
      Betriebssystem oder ganz grundlegende Geräteinformationen. Weiters speichert Vimeo Informationen über welche
      Webseite Sie den Vimeo-Dienst nutzen und welche Handlungen (Webaktivitäten) Sie auf unserer Webseite ausführen. Zu
      diesen Webaktivitäten zählen beispielsweise Sitzungsdauer, Absprungrate oder auf welchen Button Sie auf unserer
      Webseite mit eingebauter Vimeo-Funktion geklickt haben. Diese Handlungen kann Vimeo mit Hilfe von Cookies und
      ähnlichen Technologien verfolgen und speichern.</p>
    <p>Falls Sie als registriertes Mitglied bei Vimeo eingeloggt sind, können meistens mehr Daten erhoben werden, da
      möglicherweise mehr Cookies bereits in Ihrem Browser gesetzt wurden. Zudem werden Ihre Handlungen auf unsere
      Webseite direkt mit Ihrem Vimeo-Account verknüpft. Um dies zu verhindern müssen Sie sich, während
      des &#8220;Surfens&#8221; auf unserer Webseite, von Vimeo ausloggen.</p>
    <p>Nachfolgend zeigen wir Ihnen Cookies, die von Vimeo gesetzt werden, wenn Sie auf einer Webseite mit integrierter
      Vimeo-Funktion, sind. Diese Liste erhebt keinen Anspruch auf Vollständigkeit und geht davon aus, dass Sie keinen
      Vimeo-Account haben.</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> player<br/>
      <strong className="adsimple-311173621">Wert:</strong> &#8220;&#8221;<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Einstellungen,
      bevor Sie ein eingebettetes Vimeo-Video abspielen. Dadurch bekommen Sie beim nächsten Mal, wenn Sie ein
      Vimeo-Video ansehen, wieder Ihre bevorzugten Einstellungen.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach einem Jahr</p>
    <p>
      <strong className="adsimple-311173621">Name</strong>: vuid<br/>
      <strong className="adsimple-311173621">Wert: </strong>pl1046149876.614422590311173621-4<strong
        className="adsimple-311173621">
        <br/>
        <strong className="adsimple-311173621">Verwendungszweck: </strong>
      </strong>Dieses Cookie sammelt Informationen über Ihre Handlungen auf Webseiten, die ein Vimeo-Video eingebettet
      haben.<strong className="adsimple-311173621">
        <br/>
        <strong className="adsimple-311173621">Ablaufdatum: </strong>
      </strong>nach 2 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Anmerkung:</strong> Diese beiden Cookies werden immer gesetzt, sobald Sie
      sich auf einer Webseite mit einem eingebetteten Vimeo-Video befinden. Wenn Sie das Video ansehen und auf die
      Schaltfläche klicken, um beispielsweise das Video zu &#8220;teilen&#8221; oder zu &#8220;liken&#8221;, werden
      weitere Cookies gesetzt. Dabei handelt es sich auch um Drittanbieter-Cookies wie _ga oder _gat_UA-76641-8 von
      Google Analytics oder _fbp von Facebook. Welche Cookies hier genau gesetzt werden, hängt von Ihrer Interaktion mit
      dem Video ab.</p>
    <p>Die folgende Liste zeigt einen Ausschnitt möglicher Cookies, die gesetzt werden, wenn Sie mit dem Vimeo-Video
      interagieren:</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> _abexps<br/>
      <strong className="adsimple-311173621">Wert:</strong> %5B%5D<br/>
      <strong className="adsimple-311173621">Verwendungszweck: </strong>Dieses Vimeo-Cookie hilft Vimeo, sich an die von
      Ihnen getroffenen Einstellungen zu erinnern. Dabei kann es sich zum Beispiel um eine voreingestellte Sprache, um
      eine Region oder einen Benutzernamen handeln. Im Allgemeinen speichert das Cookie Daten darüber, wie Sie Vimeo
      verwenden.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach einem Jahr</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> continuous_play_v3<br/>
      <strong className="adsimple-311173621">Wert:</strong> 1<br/>
      <strong className="adsimple-311173621">Verwendungszweck: </strong>Bei diesem Cookie handelt es sich um ein
      Erstanbieter-Cookie von Vimeo. Das Cookie sammelt Informationen wie Sie das Vimeo-Service verwenden.
      Beispielsweise speichert das Cookie, wann Sie ein Video pausieren bzw. wieder abspielen.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach einem Jahr</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> _ga<br/>
      <strong className="adsimple-311173621">Wert:</strong> GA1.2.1522249635.1578401280311173621-7<br/>
      <strong className="adsimple-311173621">Verwendungszweck: </strong>Dieses Cookie ist ein Drittanbieter-Cookie von
      Google. Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es
      zur Unterscheidung der Websitebesucher.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> _gcl_au<br/>
      <strong className="adsimple-311173621">Wert:</strong> 1.1.770887836.1578401279311173621-3<br/>
      <strong className="adsimple-311173621">Verwendungszweck: </strong>Dieses Drittanbieter-Cookie von Google AdSense
      wird verwendet, um die Effizienz von Werbeanzeigen auf Websites zu verbessern.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 3 Monaten</p>
    <p>
      <strong className="adsimple-311173621">Name:</strong> _fbp<br/>
      <strong className="adsimple-311173621">Wert:</strong> fb.1.1578401280585.310434968<br/>
      <strong className="adsimple-311173621">Verwendungszweck:</strong> Das ist ein Facebook-Cookie. Dieses Cookie wird
      verwendet, um Werbeanzeigen bzw. Werbeprodukte von Facebook oder anderen Werbetreibenden einzublenden.<br/>
      <strong className="adsimple-311173621">Ablaufdatum:</strong> nach 3 Monaten</p>
    <p>Vimeo nutzt diese Daten unter anderem, um das eigene Service zu verbessern, um mit Ihnen in Kommunikation zu
      treten und um eigene zielgerichtete Werbemaßnahmen zu setzen. Vimeo betont auf seiner Website, dass bei
      eingebetteten Videos nur Erstanbieter-Cookies (also Cookies von Vimeo selbst) verwendet werden, solange man mit
      dem Video nicht interagiert.</p>
    <h3 className="adsimple-311173621">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Vimeo hat den Hauptsitz in White Plains im Bundesstaat New York (USA). Die Dienste werden aber weltweit
      angeboten. Dabei verwendet das Unternehmen Computersysteme, Datenbanken und Server in den USA und auch in anderen
      Ländern. Ihre Daten können somit auch auf Servern in Amerika gespeichert und verarbeitet werden. Die Daten bleiben
      bei Vimeo so lange gespeichert, bis das Unternehmen keinen wirtschaftlichen Grund mehr für die Speicherung hat.
      Dann werden die Daten gelöscht oder anonymisiert. Vimeo entspricht dem EU-U.S. Privacy Shield Framework und darf
      somit Daten von Usern aus der EU sammeln, nutzen und in die USA übertragen.</p>
    <h3 className="adsimple-311173621">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Sie haben immer die Möglichkeit, Cookies in Ihrem Browser nach Ihren Wünschen zu verwalten. Wenn Sie
      beispielsweise nicht wollen, dass Vimeo Cookies setzt und so Informationen über Sie sammelt, können Sie in Ihren
      Browser-Einstellungen Cookies jederzeit löschen oder deaktivieren. Je nach Browser funktioniert dies ein bisschen
      anders. Bitte beachten Sie, dass möglicherweise nach dem Deaktivieren/Löschen von Cookies diverse Funktionen nicht
      mehr im vollen Ausmaß zur Verfügung stehen. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser
      verwalten bzw. löschen.</p>
    <p>
      <a className="adsimple-311173621" href="https://support.google.com/chrome/answer/95647?tid=311173621"
        target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a className="adsimple-311173621" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311173621"
        target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a className="adsimple-311173621"
        href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311173621"
        target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
        Ihrem Computer abgelegt haben</a>
    </p>
    <p>
      <a className="adsimple-311173621"
        href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311173621"
        target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a className="adsimple-311173621"
        href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311173621" target="_blank"
        rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Falls Sie ein registriertes Vimeo-Mitglied sind, können Sie auch in den Einstellungen bei Vimeo die verwendeten
      Cookies verwalten.</p>
    <p>Vimeo ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
      Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
      className="adsimple-311173621"
      href="https://www.privacyshield.gov/participant?id=a2zt00000008V77AAE&amp;status=Active" target="_blank"
      rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt00000008V77AAE&amp;status=Active</a>.
      Mehr über den Einsatz von Cookies bei Vimeo erfahren Sie auf <a className="adsimple-311173621"
      href="https://vimeo.com/cookie_policy?tid=311173621"
      target="_blank"
      rel="follow noopener noreferrer">https://vimeo.com/cookie_policy</a>,
      Informationen zum Datenschutz bei Vimeo können Sie auf <a className="adsimple-311173621"
      href="https://vimeo.com/privacy?tid=311173621"
      target="_blank"
      rel="follow noopener noreferrer">https://vimeo.com/privacy</a> nachlesen.
    </p>
    <p style={{ marginTop: 15 }}>Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland"
      href="https://www.adsimple.de/datenschutz-generator/"
      target="_blank" rel="follow" style={{ textDecoration: 'none' }}>Datenschutz
      Generator</a> von AdSimple in Kooperation mit <a href="https://www.warkly.de" target="_blank" rel="follow"
      title="">warkly.de</a>
    </p>
  </div>
);
